<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            illustrative dna
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Welcome to Illustrative DNA!')}} 👋
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('Please sign-in to your manage account')}}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              :label="$t('Username or Email Address')"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('user name')"
                  autofocus
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{$t('Password')}}</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>{{$t('Forgot Password')}}?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    :placeholder="$t('password')"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="rememberMe"
                name="checkbox-1"
              >
                {{$t('Remember me')}}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="$store.getters.getLoader"
            >
            <spinner-loader />
              {{$t('Log In')}}
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          © {{$t('illustrative dna. 2021. All Rights Reserved')}}.
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      rememberMe: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.commit('setLoader', true)
          useJwt.login({
            userNameOrEmail: this.userEmail,
            password: this.password,
            rememberMe: this.rememberMe,
          })
            .then(response => {
              if (response.statusCode === 200) {
                const userData = response.result
                useJwt.setToken(response.result.token)
                useJwt.setRefreshToken(response.result.token)
                useJwt.setUserData(userData)

                this.$router.push(getHomeRouteForLoggedInUser(userData.userRole))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${userData.fullName}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: this.$t('You have successfully logged into the system.'),
                      },
                    })
                  })
              } else {
                AlertService.warning(this, this.$t(response.message))
              }
              this.$store.commit('setLoader', false)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
